import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import { addYears } from 'date-fns'

import {
  Layout,
  ProposalHero,
  ProposalImageShuffle,
  ProposalProcess,
  ProposalTimeline,
  ProposalProjects,
  ProposalPassword,
} from '@organisms'

import {
  ProposalDeliverables,
  ProposalFees,
  ProposalClientDeliverables,
  ProposalPreFooter,
  ProposalParagraph,
} from '@molecules'

import { ProposalCTAHeader, ProposalSubHero } from '@atoms'
import { COLOR } from '@constants'
import { contentfulReducer } from '@helpers'

import { Seo } from '@atoms'

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const Head = ({ data }) => {
  const url = data?.contentfulProposal?.urlSlug
  const title = toTitleCase(url.split('-').join(' '))

  return <Seo title={title} />
}

const Proposal = (props) => {
  const [proposalData, setProposalData] = useState()
  const [error, setError] = useState(false)

  const data = dotPath('data.contentfulProposal', props)
  const gradient = dotPath('data.gradient', props)
  const mobileGradient = dotPath('data.mobileGradient', props)

  const currentDate = Date.parse(new Date())

  const expirationDateUnique = `expirationDate${data.urlSlug}`
  const proposalId = dotPath('pageContext.proposalId', props)
  const urlSlug = dotPath('urlSlug', data)

  const handlePasswordSubmit = ({ password }) => {
    const reqBody = {
      password: password,
      data: data,
      proposalId: proposalId,
      urlSlug: urlSlug,
    }

    fetch(`/api/get-proposal`, {
      method: `POST`,
      body: JSON.stringify(reqBody),
      headers: {
        'content-type': `application/json`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else {
          setError(true)
          throw new Error('Incorrect Password')
        }
      })
      .then((res) => {
        const yearFromLogin = addYears(new Date(), 1)

        // id & date stored in localStorage after serverless verifies password
        const localVariable = JSON.stringify({
          year: yearFromLogin,
          id: res.sys.id,
        })
        typeof window !== undefined &&
          localStorage.setItem(`expirationDate${urlSlug}`, localVariable)

        setProposalData(contentfulReducer(res.fields))
        setError(false)
      })
      .catch((error) => {
        console.log('Error:', error)
      })
  }

  const canParse =
    typeof window !== `undefined` &&
    !isNilOrEmpty(localStorage[expirationDateUnique])

  // js usable localStorage
  const parsedLocal = canParse && JSON.parse(localStorage[expirationDateUnique])

  const expiration =
    typeof window !== `undefined` &&
    !isNilOrEmpty(localStorage[expirationDateUnique])
      ? Date.parse(parsedLocal.year)
      : null
  const [isExpired, setIsExpired] = useState(true)

  useEffect(() => {
    setIsExpired(isNilOrEmpty(expiration) ? true : expiration < currentDate)
  }, [expiration, currentDate])

  // checks for valid token after proposalData has been set by the serverless function
  useEffect(() => {
    !isNilOrEmpty(proposalData) &&
      setIsExpired(isNilOrEmpty(expiration) ? true : expiration < currentDate)
  }, [proposalData, currentDate, expiration])

  // fetches & sets data if user has previously been authenticated
  // this will check the id in localStorage against the server
  // to ensure user has been previously authenticated

  useEffect(() => {
    if (!isExpired && isNilOrEmpty(proposalData)) {
      const req = { id: parsedLocal.id, proposalId: proposalId }

      fetch(`/api/get-proposal`, {
        method: `POST`,
        body: JSON.stringify(req),
        headers: {
          'content-type': `application/json`,
        },
      })
        .then((res) => res.json())
        .then((res) => setProposalData(contentfulReducer(res.fields)))
        .catch((error) => {
          typeof window !== `undefined` &&
            localStorage.removeItem(`expirationDate${urlSlug}`)
          setIsExpired(true)

          console.log('Error:', error)
        })
    }
  }, [isExpired, parsedLocal.id, proposalId, proposalData, urlSlug])

  const layout = dotPath('layout', proposalData)

  const contactData = {
    serverData: dotPath('primaryContact', proposalData),
    imageData: dotPath('primaryContact.image', data),
  }

  const layoutSwitch =
    layout &&
    layout.map((item, i) => {
      const typename = item.__typename

      switch (typename) {
        case 'deliverables': {
          return (
            !isNilOrEmpty(item.deliverableLayout) && (
              <ProposalDeliverables
                data={item.deliverableLayout}
                title={item.title}
                key={i}
                typename={item.__typename}
              />
            )
          )
        }
        case 'textGrid': {
          return (
            !isNilOrEmpty(item.layout) && (
              <ProposalDeliverables
                title={item.title}
                data={item.layout}
                key={i}
                backgroundColor={item.backgroundColor}
                typename={item.__typename}
              />
            )
          )
        }
        case 'fees': {
          return (
            !isNilOrEmpty(item.feesLayout) && (
              <ProposalFees
                data={item.feesLayout}
                key={i}
                feesTotal={item.feesTotal}
              />
            )
          )
        }
        case 'clientDeliverables': {
          return (
            !isNilOrEmpty(item.clientDeliveralbesLayout) && (
              <ProposalClientDeliverables
                data={item.clientDeliveralbesLayout}
                key={i}
              />
            )
          )
        }
        case 'timeline': {
          return (
            !isNilOrEmpty(item.timelineLayout) && (
              <ProposalTimeline data={item.timelineLayout} key={i} />
            )
          )
        }
        case 'caseStudies': {
          const caseStudyImages = dotPath('layout', data).map((d) => {
            return dotPath('caseStudyLayout', d)
          })[i]

          const caseStudyData = item.caseStudyLayout.map((c, i) => {
            return { ...c, image: caseStudyImages[i] }
          })

          return (
            !isNilOrEmpty(item.caseStudyLayout) && (
              <ProposalProjects data={caseStudyData} key={i} />
            )
          )
        }
        case 'paragraph': {
          return <ProposalParagraph {...item} key={i} />
        }
        default: {
          return null
        }
      }
    })

  const toggleProcess =
    isNilOrEmpty(dotPath('toggleProcessOfRiffing', proposalData)) ||
    dotPath('toggleProcessOfRiffing', proposalData)
  const toggleReady =
    isNilOrEmpty(dotPath('toggleReadyToGetStarted', proposalData)) ||
    proposalData.toggleReadyToGetStarted

  const showPassword = isExpired

  return (
    <Layout
      color={COLOR.PURPLE}
      fill={COLOR.WHITE}
      proposal
      title={dotPath('clientName', proposalData)}
    >
      {showPassword && (
        <ProposalPassword
          isExpired={isExpired}
          handlePasswordSubmit={handlePasswordSubmit}
          error={error}
        />
      )}

      <ProposalHero
        client={dotPath('clientName', proposalData)}
        download={('downloadLink', proposalData)}
        body={
          !isNilOrEmpty(dotPath('subtitleParagraph', proposalData)) &&
          proposalData.subtitleParagraph
        }
      />
      <ProposalSubHero />
      <ProposalImageShuffle />
      {toggleProcess && <ProposalProcess />}
      {layoutSwitch}
      <ProposalCTAHeader toggleReady={toggleReady} />
      {!isNilOrEmpty(proposalData) && (
        <ProposalPreFooter
          toggleReady={toggleReady}
          link={dotPath('docuSignLink', proposalData)}
          download={dotPath('downloadLink', proposalData)}
          contact={contactData}
          gradient={gradient}
          mobileGradient={mobileGradient}
        />
      )}
    </Layout>
  )
}

export const ProposalPageQuery = graphql`
  query proposalQuery($proposalId: String!) {
    contentfulProposal(contentful_id: { eq: $proposalId }) {
      urlSlug
      primaryContact {
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      layout {
        ... on ContentfulCaseStudies {
          name
          caseStudyLayout {
            image {
              title
              id
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    gradient: file(relativePath: { eq: "proposal/bigChungus.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    mobileGradient: file(relativePath: { eq: "proposal/mobileGradient.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`

export default Proposal
